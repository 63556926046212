import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-logout',
  templateUrl: './auth-logout.component.html',
  styleUrls: ['./auth-logout.component.scss']
})
export class AuthLogoutComponent implements OnInit {

  constructor(private authService:AuthenticationService) { }

  ngOnInit(): void {
    window.location.replace(environment.identityServiceUrl);
    this.authService.logout();
  }

}
